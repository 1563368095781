.menus {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;    
    background: conic-gradient(from 90deg at 50% 50%,transparent 0deg,#851919 97.12deg,transparent 1turn),#c23131;
}

.menus .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.content-left, .content-right {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: flex-start;
}

.content-left {
    width: calc(((100vw)/9) * 6);
    align-items: flex-start;
}

.content-right {
    width: calc(((100vw)/9) * 3);
    align-items: flex-start;
    justify-content: space-around;
}

.menus:before {
    background-image: repeating-linear-gradient(var(--ep-grid-line-direction),var(--ep-grid-line-column-color),var(--ep-grid-line-column-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns)));
    background-size: calc(100% + var(--ep-grid-line-width)) 100%;
    bottom: 0;
    content: "";
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--ep-grid-line-max-width);
    min-height: 100vh;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: var(--ep-grid-line-z-index);
    animation: slideDownFade 1.5s ease-out;
}

.menuopen {
    --ep-grid-line-color: #ffffff3b;
    --ep-grid-line-columns: 9;
    --ep-grid-line-width: 1.5px;
    background-color: #f2f2f2;
    margin: 0;
}

.menus button.close {
    background: url(https://vivalagrid.com/backend/wp-content/uploads/2023/07/close-wh.svg) no-repeat;
}

.menu-main {
    display: flex;
    flex-direction: column;
    font-size: 12rem;
    background-color: #faebd700;
    padding-left: calc((100vw)/9);
    text-transform: lowercase;
    gap: 3rem;
    font-weight: 900;
    font-family: 'Cabin';
}

.menu-main {
    animation: slideUp-1 .9s ease-out;
}

.menu-social {
    display: flex;
    flex-direction: column;
    font-size: 8rem;
    background-color: #faebd700;
    text-transform: lowercase;
    gap: 3rem;
}

.menu-main a, .menu-social a {
    color: white;
    text-decoration-line: blink;
    font-family: 'Cabin';
    font-weight: 400;
    width: fit-content;
}

.menu-main a, .menu-main button {
    font-weight: 900;
    font-family: 'Cabin';
}

.menu-main button {
    background-color: red;
    font-size: 12rem;
    background-color: #faebd700;
    text-align: left;
    padding: 0;
    color: white;
    border: 0;
    text-transform: lowercase;
    width: fit-content;
}

button#animate-back {
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: Cabin,sans-serif;
    font-size: 5rem;
    font-weight: 100;
    margin-left: 11.11111vw;
    padding: 0;
    background-color: #58414100;
    width: 100%;
    margin: 0;
    padding-left: calc((100vw)/9);
}

.content-right .contact {
    display: flex;
    flex-direction: column;
    font-size: 8rem;
    color: white;
    width: 50%;
    font-family: 'Cabin';
    line-height: 12rem;
}

.menu-main button:hover, .menu-main a:hover {
    background-color: black;
    padding: 0 5rem;
    transition: .5s;
    font-family: 'Cantata One';
    font-weight: 100;
    color: #ffffff;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/arrow-cursor.png) 4 12, auto;
}

.menu-social a:hover,.contact a:hover {
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/07/arrow-cursor-bl.png) 4 12,auto;
    font-weight: 900;
}

.content-right .contact a {
    text-decoration: none;
    color: white;
}

.content-left .redirect {
    animation: slideUp-1 1s ease-out;
}

@keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }

    25% {
      transform: translateY(-75%);
    }

    50% {
        transform: translateY(-50%);
    }

    75% {
        transform: translateY(-25%);
    }

    100% {
      transform: translateY(0);
    }
}

@keyframes slideDownFade {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    25% {
        transform: translateY(-100%);
        opacity: 0;
    }

    50% {
        transform: translateY(-100%);
        opacity: 0;
    }

    75% {
        transform: translateY(-25%);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


@keyframes slideUp-1 {
    0% {
      transform: translateY(100%);
    }

    50% {
        transform: translateY(50%);
    }

    100% {
      transform: translateY(0);
    }
}

@keyframes backRotate {
    0% {
        background: conic-gradient(from 90deg at 20% 30%, transparent 0deg, rgb(133, 25, 25) 90deg, transparent 1turn), rgb(194, 49, 49);
    }
    50% {
        background: conic-gradient(from 90deg at 20% 30%, transparent 0deg, rgb(153, 40, 40) 120deg, transparent 1turn), rgb(194, 49, 49);
    }
    50% {
        background: conic-gradient(from 90deg at 20% 30%, transparent 0deg, rgb(133, 25, 25) 90deg, transparent 1turn), rgb(194, 49, 49);
    }
}

@keyframes moveGradient {
    0% {
        background-position: 100% 0;
        opacity: .95;
    }
    100% {
        background-position: 0 100%;
    }
}


@media only screen and (max-width: 1025px) {

    .menuopen {
        --ep-grid-line-columns: 5;
    }

    .menus .content {
        flex-direction: column;
        justify-content: flex-end;
    }

    .content-left, .content-right {
        width: 100vw;
        height: fit-content;
        gap: 10rem;
    }

    .menu-main, .menu-social, .content-right .contact {
        padding-left: calc((100vw)/5);
        width: 100%;
    }

    .menus button#cta-button{
        margin-left: calc((100vw)/5);
    }

    .content {
        gap: 20rem;
    }

    .content-left .redirect {
        display: none;
    }

}

@media only screen and (max-width: 600px) {

    .menuopen {
        --ep-grid-line-columns: 2;    
    }

    .menus button#cta-button {
        margin-top: 0;
    }

    .menu-main, .menu-social, .content-right .contact {
        padding-left: 5vw;
    }

    .menus button#cta-button{
        margin-left: 5vw;
    }

    .menu-main button {
        font-size: 15rem;
    }

    .content-right .contact {
        font-size: 10rem;
        line-height: 15rem;
    }

    .menu-social {
        font-size: 10rem;
    }

    .menu-main {
        font-size: 15rem;
    }   

    .menus .content {
        gap: 20rem;
        padding-bottom: 20rem;
    }

    .menus {
        height: calc(100 * var(--vh));
    }

    .menus .content {
        justify-content: flex-end;
    }
}