@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cantata+One&display=swap');

html{
    font-size: 5px;
}

a:not([href]){
    opacity:.5;
}

::selection {
    background-color: black;
    color: white;
}

body {
    background-color: #F2F2F2;
    --ep-grid-line-color: #22222212;
    --ep-grid-line-columns: 9;
    --ep-grid-line-width: 1.5px;
    margin: 0;
    overflow-x: hidden;
    cursor: url('https://vivalagrid.com/backend/wp-content/uploads/2023/07/circle-cursor-bl.png'), auto;
    font-family: 'Cabin';
}

a, span, h1, h2, h3, h4, h5, h6, p, button {
    font-family: 'Cabin';
}

:root {
    --ep-grid-line-direction: 90deg;
    --ep-grid-line-column-color: transparent;
    --ep-grid-line-columns: 9; /* Changed from 12 to 9 */
    --ep-grid-line-width: 1px;
    --ep-grid-line-color: #eee;
    --ep-grid-line-max-width: 100%;
    --ep-grid-line-z-index: 0;
}

body:before {
    background-image: repeating-linear-gradient(
        var(--ep-grid-line-direction),
        var(--ep-grid-line-column-color),
        var(--ep-grid-line-column-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),
        var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),
        var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns))
    );
    background-size: calc(100% + var(--ep-grid-line-width)) 100%;
    bottom: 0;
    content: "";
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--ep-grid-line-max-width);
    min-height: 100vh;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: var(--ep-grid-line-z-index);
    animation: linesDownFade 2.5s ease-out;
}

@keyframes linesDownFade {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    25% {
        transform: translateY(-75%);
        opacity: .25;
    }

    50% {
        transform: translateY(-50%);
        opacity: .5;
    }

    75% {
        transform: translateY(-25%);
        opacity: .75;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

button#cta-button {
    font-weight: 500;
    border-radius: 0;
    border: 1px solid #000000;
    background-color: transparent;
    color: black;
    font-size: 3rem;
    padding: 1em 4em;
}

div#cta {
    padding-left: calc((100%)/9);
    padding-top: 13rem;
    width: 50%;
}

button#cta-button {
    font-weight: 500;
    border-radius: 0;
    color: black;
    font-size: 5rem;
    margin-left: calc((100vw)/9);
    border: none;
    padding: 0;
}

#line-1 rect {
    width: 31px;
    height: 4px;
}

#line-2 rect {
    width: 26px;
    height: 4px;
}

#line-3 rect {
    width: 4px;
    height: 4px;
    fill: #B5150B;
}

div#line-1, div#line-2, div#line-3 {
    height: 4px;
    margin: 1.5px;
}

button.menu {
    border: 0;
    background: none;
    display: flex;
    align-items: flex-start;
}

button.menu svg{
    height: fit-content;
    display: grid;
}

#important-text{
    padding-left: calc((100%)/9);
    font-weight: 500;
    font-size: 13rem;
    color: black;
    width: calc((100%)/9*7 - 14px);
}

#main-text{
    padding-left: calc((100%)/9);
    font-weight: 500;
    font-size: 20rem;
    color: black;
    width: 13ch;
    line-height: 1.25;
    animation: startFast 2s;
}

#main-text-regular{
    padding-left: calc((100%)/9);
    font-weight: 400;
    font-size: 20rem;
    color: black;
    width: calc((100%)/9*7 - 14px);
    animation: startMed 2s;
}

#main-text-cantata{
    padding-left: calc((100%)/9);
    font-family: 'Cantata One', serif;
    font-weight: 400;
    font-size: 20rem;
    color: #E3E3E3;
    width: calc((100%)/9*7 - 14px);
    display: flex;
    width: fit-content;
    animation: startSlow 2s;
}

button.cta {
    font-weight: 500;
    border-radius: 0;
    border: 1px solid #000000;
    background-color: transparent;
    color: black;
    font-size: 3rem;
    padding: 1em 4em;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease-in-out;
    cursor: url('https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png'), auto;
}

button.cta:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black;
    transition: width 0.3s ease-in-out;
    z-index: -1;
}

button.menu {
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png), auto;
}

button.cta:hover:after {
    width: 100%;
}

button.cta:hover {
    color: white;
}

div#viva-start {
    z-index: 0;
    right: calc((100%)/9);
    text-align: right;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

#kolo{
    filter: blur(10px) opacity(0.3);
}

.header {
    display: flex;
    justify-content: space-between;
}

div.menu {
    padding-right: calc((100vw)/9 - 37px);
    padding-top: calc(13rem + 12px);
    cursor: url('https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png'), auto;
    justify-content: flex-end;
    display: flex;
    width: 100%;
}

.second, .offer, .fourth {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
}

.works {
    display: flex;
    flex-direction: column;
    min-height: 200vh;
}

.start {
    min-height: 85vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
}

div.start-photo, div.absolute-image, div.absolute-image-two, div.photo {
    text-align: right;
    position: absolute;
    width: fit-content;
    z-index: -1;
    transition: .3s ease-out;
}

div.start-photo, div.absolute-image {
    right: calc((100vw)/9 - 2px);
}

div.absolute-image-two {
    right: calc((100vw)/9 * 2 - 4px);
}

div.photo {
    right: calc((100vw)/9 * 2 - 4px);
}

.start-photo img {
    object-fit: cover;
    transition: .3s ease-out;
    width: calc((100vw)/9 * 3 - 4px);
    animation: startSlide 2s;
}

.start-text {
    width: 100%;
    gap: 20rem;
    display: flex;
    flex-direction: column;
}

.start-main {
    display: flex;
    flex-direction: column;
}

button#counting {
    border: 0;
    margin-left: calc((100%)/9);
    font-size: 5rem;
    padding: 0;
    background-color: #fff0;
}

.second-text {
    display: flex;
    padding-top: 10vh;
}

.light-argesta h1 {
    font-weight: 100;
}

.second .second-main {
    height: 75vh;
}

.second .text {
    height: 60vh;
}

.header {
    display: flex;
    justify-content: space-between;
    height: 15vh;
    position: sticky;
    z-index: 100;  
    top:0;
}

.second {
    height: 100vh;
}

.offer-main h2#main-text-cantata:after {
    opacity: 0;
}

.offer-main {
    width: 100vw;
    position: relative;
}

.offer-main h2#main-text-cantata, .fourth h2#main-text-cantata {
    position: absolute;
    top: 2rem;
    z-index: -1;
    width: 100%;
}

.offer button.tablinks {
    position: relative;
    background: no-repeat;
    border: 0;
    color: #c3c3c3;
    display: flex;
    font-size: 10rem;
    text-align: left;
    transition: .5s ease-out;
    padding-left: 0;
}

.offer button.tablinks span.second-font {
    font-family: Cantata One;
    font-size: 9rem;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 1px;
    transition: font-size .1s ease-out;
}

.offer button.tablinks:hover {
    color: transparent;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png) 4 12,auto;
}

.offer button.tablinks:hover span.second-font {
    opacity: 1;
    color: #000;
}

.offer .tab {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5rem;
    padding-left: calc((100vw)/9 * 1);
    width: 100%;
}

.offer {
    display: flex;
    align-content: space-around;
}

div#creation, div#transformation, div#mind, div#strategy {
    position: absolute;
    right: calc((100vw)/9*2 - 4px);
    top: 50vh;
    font-size: 6rem;
    width: calc((100vw)/9*3 - 6px);
}

.fourth .photo img {
    height: 40vh;
    object-fit: cover;
}

.fourth .photo {
    position: absolute;
    right: 0;
    top: 40vh;
}

.fourth .text {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.redirect {
    position: relative;
}

.offer .absolute-image img, .offer .absolute-image-two img, .fourth .photo img  {
    object-fit: cover;
    mix-blend-mode: darken;
    position: absolute;
    transition: .3s ease-out;
}

.offer .absolute-image-two img {
    width: calc((100vw)/9 * 1 - 2px);
}

.fourth .photo img {
    mix-blend-mode: normal;
    right: 0;
    width: calc((100vw)/9 * 4 - 8px);
    filter: sepia(1) grayscale(1) opacity(0.5) brightness(1.5);
    object-position: bottom;
}

.line {
    width: 100%;
    position: absolute;
    border-top: 1px solid #00000010;
    transition: top 0.2s ease-in-out;
}

.menu:hover #line1 {
    transform: translateY(1px);
}

.menu:hover #line2 {
    width: 30px;
    transform: translateY(2px);
}

.menu:hover #line3 {
    width: 30px;
    transform: translateY(3px);
}

.menu rect {
    transition: transform 0.4s ease;
}

#line1, #line2, #line3 {
    transition: all 0.4s ease;
}

.word:hover {
    transition: all 0.4s ease;
}

.word:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/arrow-cursor.png) 4 12, auto;
    padding: 0px 0 0 10px;
}

.fourth-main .word:hover {
    background-color: black;
    color: white;
    transition: all 0.4s ease;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/arrow-cursor.png) 4 12, auto;
    padding: 0px 0 0 10px;
}

.offer-main #main-text-regular {
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/cross-cursor.png), auto;
    width:fit-content;
  }

#cursor-pointer {
    background-blend-mode: soft-light;
    cursor: none;
    height: 250px;
    border-radius: 100%;
    mix-blend-mode: color-dodge;
    pointer-events: none;
    position: fixed;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: width .3s,height .3s;
    -webkit-user-select: none;
    user-select: none;
    width: 250px;
    z-index: 10000;
    background-color: #c23131;
    filter: blur(5px);
}

@-webkit-keyframes colorchange {
    0% { transform: rotate(0); }
    14% { transform: rotate(14deg); }
    28% { transform: rotate(34deg); }
    42% { transform: rotate(54deg); }
    71% { transform: rotate(71deg); }
    85% { transform: rotate(90deg); }
    100% { transform: rotate(180deg); }
  }
  
@-moz-keyframes colorchange {
    0% { transform: rotate(0); }
    14% { transform: rotate(14deg); }
    28% { transform: rotate(34deg); }
    42% { transform: rotate(54deg); }
    71% { transform: rotate(71deg); }
    85% { transform: rotate(90deg); }
    100% { transform: rotate(180deg); }
  }
  
@keyframes colorchange {
    0% { transform: rotate(0); }
    14% { transform: rotate(14deg); }
    28% { transform: rotate(34deg); }
    42% { transform: rotate(54deg); }
    71% { transform: rotate(71deg); }
    85% { transform: rotate(90deg); }
    100% { transform: rotate(180deg); }
  }

div#viva-start img {
    width: calc((100%/18)*3 - 6px);
}

img:hover {
    cursor: none;
}

.tabcontent:before {
    content: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/offer-arrow.svg);
}

.tabcontent {
    display: flex;
    gap: 10rem;
}

button#cta-button {
    position: relative; 
    overflow: hidden; 
    cursor: url('https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png'), auto;
    width: calc((100%/18)*4);
    display: flex;
}

.fourth #cta-button {
    width: calc((100%/18)*6);
}
  
button#cta-button::after {
    content: ""; 
    position: absolute;  
    width: 100%;
    height: 2px;
    background: black; 
    left: -100%; 
    bottom: 0; 
    transition: left 0.5s ease;
}
  
button#cta-button:hover::after {
    left: 0; 
}

button.tablinks.active {
    color: #000000;
}

.fourth h2#main-text-cantata {
    z-index: 99;
    mix-blend-mode: overlay;
}

.offer .absolute-image img, .offer .absolute-image-two img {
    filter: sepia(1) grayscale(1);
}

button.close:hover {
    transform: rotate(180deg);
    transition: transform .6s;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/cross-cursor.png), auto;
}

.menus {
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    50% {
        transform: translateY(-50%);
      }
    100% {
      transform: translateY(0);
    }
}

@keyframes startFast {
    0% {
      transform: translateY(50rem);
      line-height: 2;
      opacity: 0;
    }
    30% {
        transform: translateY(50rem);
        line-height: 2;
        opacity: 0;
      }
    100% {
      transform: translateY(0);
      line-height: 1.25;
      opacity: 1;
    }
}

@keyframes startMed {
    0% {
      transform: translateY(70rem);
      opacity: 0;
    }
    30% {
        transform: translateY(70rem);
        opacity: 0;
      }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@keyframes startSlow {
    0% {
        transform: translateY(90rem);
        opacity: 0;
    }
    30% {
        transform: translateY(90rem);
        opacity: 0;
      }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes startSlide {
    0% {
        width: 30px;
    }
    30% {
        width: 60px;
      }
    100% {
        width: calc(33.33333vw - 4px);
    }
}

@keyframes startSlideMob {
    0% {
        width: 30px;
    }
    100% {
        width: 90%;
    }
}

@keyframes connect {
    0% {
        transform: translateY(-90rem) rotate(-90deg);
        padding-left: 0px;
        margin-top: 10rem;
    }
    100% {
        transform: translateY(0) rotate(-90deg);
        padding-left: 0px;
        margin-top: 20rem;
    }
}

.yourfoot {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 15vh;
    align-items: center;
    background-color: black;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/circle-cursor.png),auto;
}

.left, .right {
    padding-left: calc((100%)/9);
    font-size: 5rem;
    display: flex;
    gap: 5rem;
    width: calc((100vw)/9 * 3 - 4px);
    color: white;
}

.yourfoot a {
    color: white;
    cursor: url(https://vivalagrid.com/backend/wp-content/uploads/2023/07/arrow-cursor-bl.png) 4 12, auto;
    text-decoration: none;
    padding: 1rem;
    position: relative;
    display: inline-block;
    overflow: hidden;
    background: transparent;
    transition: background 0.5s ease-in-out;
}

.yourfoot a:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #c23131;
    transition: left 0.5s ease-in-out;
}

.yourfoot a:hover {
    background: #c23131;
}

.yourfoot a:hover:before {
    left: 100%;
}

.left p, .right p {
    color: white;
    text-decoration: none;
    padding: 1rem;
}
  

@media only screen and (min-width: 2100px) {
    div#viva-start img {
        width: calc((100%/18)*4 - 8px);
    }
}

@media only screen and (max-width: 1450px) {
    html {
        font-size: 4px;
    }
}

@media only screen and (max-width: 1290px) {
    html{
        font-size: 4px;
    }
}

@media only screen and (max-width: 1025px) {
    html{
        font-size: 2.5px;
    }
    body {
        --ep-grid-line-columns: 5;
    }

    button.cta {
        font-size: 6rem;
    }

    div.start-photo {
        right: 0;
    }

    .start-photo img {
        width: calc(((100vw)/9 * 4) - 4px);
    }

    button#cta-button {
        font-size: 9rem;
    }

    button.menu svg {
        width: 35px;
    }

    div.menu {
        padding-right: calc(11.11111vw - 51px);
        padding-top: 18rem;
        align-items: flex-start;
    }

    button#cta-button, .fourth #cta-button {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    html{
        font-size: 2px;
    }

    div#viva-start {
        right: calc(-100%/3);
    }

    body {
        --ep-grid-line-columns: 2;
    }

    div#lines {
        padding-right: 0;
    }

    div.menu {
        padding-right: 10%;
        padding-top: 0;
    }

    div#cta {
        padding-right: 10%;
        padding-top: 0;
        margin-top: 0;
        position: sticky;
    }

    .start {
        min-height: 250rem;
    }

    .start-photo img {
        width: 90%;
        height: 50vh;
    }

    #main-text-cantata {
        mix-blend-mode: overlay;
    }

    button#cta-button {
        margin-top: 20vh;
    }

    .header {
        align-items: center;
        height: 70rem;
    }

    button#counting {
        font-size: 10rem;
    }

    .second .second-main {
        height: 200rem;
    }

    .second .text {
        height: 250rem;
    }

    #important-text {
        font-size: 15rem;
        width: 70%;
    }

    .line.line-5 {
        display: none;
    }

    :root {
        max-width: 100%;
        overflow-x: hidden;
    }

    div#creation, div#mind, div#strategy, div#transformation {
        font-size: 10rem;
        right: 10vw;
        bottom: 0;
        width: 80vw;
    }

    .offer button.tablinks {
        font-size: 12rem;
    }

    .offer-main #main-text-regular, .offer-main h2#main-text-cantata {
        line-height: 1;
    }

    .second {
        align-content: flex-end;
    }

    div.absolute-image-two {
        width: 50vw;
        left: 0;
        right: inherit;
    }

    .offer .absolute-image-two img {
        width: 50vw;
    }

    .offer button.tablinks span.second-font {
        font-size: 11rem;
    }

    .second-text {
        padding-top: 0;
    }

    .offer {
        height: 150vh;
        margin-top: 100rem;
        margin-bottom: 50rem;
        align-content: flex-start;
    }

    div.absolute-image {
        right: inherit;
    }

    .offer div.absolute-image img {
        height: 150rem;
        width: 50vw;
    }

    button#cta-button {
        width: 100%;
    }

    .offer-main, div#creation, div#mind, div#strategy, div#transformation {
        margin-top: 20vh;
    }

    .fourth .photo img {
        width: 50vw;
        object-position: -80rem;
        height: 60vh;
    }

    .fourth .photo {
        top: 30vh;
    }

    .fourth h2#main-text-cantata {
        mix-blend-mode: difference;
        filter: blur(3px) opacity(0.5);
    }

    button.tablinks{
        padding-left: 0;
    }

    .start-photo img {
        object-fit: cover;
        transition: .3s ease-out;
        animation: startSlideMob 2s;
    }
}

@media only screen and (max-width: 365px) {
    html {
        font-size: 1.8px;
    }

    .offer {
        height: 100vh;
    }
}

@media only screen and (max-width: 350px) {
    html {
        font-size: 1.7px;
    }
}