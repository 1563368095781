.photo-another img,.photo-large img, .photo-mid img, .photo-small img {
    object-fit: cover;
    overflow: initial;
}

.photo-small {
    width: calc(((100vw)/9 * 2) - 4px);
}

.photo-mid {
    width: calc(((100vw)/9 * 3) - 6px);
}

.photo-large {
    width: calc(((100vw)/9 * 4) - 8px);
}

.photo-another {
    width: calc(((100vw)/9 * 3) - 6px);
}

.hover-text {

    left: 0;
    opacity: 0;
    position: absolute;
    bottom: 0;
    transition: opacity .3s ease;
    padding: 10rem;
    font-weight: 400;
    color: white;
    min-width: 40%;
    min-width: 60%;
    background: #12191d;
    filter: opacity(.9);
}

.photo-small .hover-text {
    font-size: 4rem;
}

.photo-mid .hover-text, .photo-another .hover-text {
    font-size: 4rem;
}

.photo-large .hover-text {
    font-size: 4rem;
}

.maska {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: transform 0.5s ease;
}

.photo-mid img, .photo-another img, .photo-large img, .photo-small img {
    height: 100%;
    width: 100%;
}

.left1 {
    margin-left: 0;
}

.left2 {
    margin-left: calc((100%)/9*1);
}

.left3 {
    margin-left: calc((100%)/9*2);
}

.left4 {
    margin-left: calc((100%)/9*3);
}

.left5 {
    margin-left: calc((100%)/9*4);
}

.left6 {
    margin-left: calc((100%)/9*5);
}

.left7 {
    margin-left: calc((100%)/9*6);
}

.left8 {
    margin-left: calc((100%)/9*7);
}

.maska {
    position: relative; 
}

.maska:hover .hover-text {
    opacity: 1;
}

.min10 {
    margin-top: -10rem;
}

.min30 {
    margin-top: -30rem;
}

.min60 {
    margin-top: -60rem;
}

.cta-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 10rem;
    justify-content: flex-end;
    min-height: 120vh;
    width: 100vw;
    padding-bottom: 20rem;
    padding-left: calc((100%)/9);
}

h2.cta {
    font-size: 20rem;
    font-weight: 500;
}

p#some-text-cta {
    font-size: 13rem;
    font-weight: 500;
    width: calc((100%)/9*7);
    padding-bottom: 20rem;
}


.photo-small.left2 img {
    width: calc((100vw)/9*2);
}

@media only screen and (max-width: 1025px) {

    .left1 {
        margin-left: 0;
    }
    
    .left2 {
        margin-left: 0;
    }
    
    .left3 {
        margin-left: calc((100%)/6*2);
    }
    
    .left4 {
        margin-left: calc((100%)/6*2);
    }
    
    .left5 {
        margin-left: calc((100%)/6*3);
    }
    
    .left6 {
        margin-left: calc((100%)/6*3);
    }
    
    .left7 {
        margin-left: calc((100%)/6*4);
    }
    
    .left8 {
        margin-left: calc((100%)/6*4);
    }

    .photo-small {
        width: calc(((100vw)/6 * 2) - 4px);
    }
    
    .photo-mid {
        width: calc(((100vw)/6 * 3) - 4px);
    }
    
    .photo-large {
        width: calc(((100vw)/6 * 4) - 4px);
    }
    
    .photo-another {
        width: calc(((100vw)/6 * 3) - 4px);
    }

}

@media only screen and (max-width: 600px) {
    .left1, .left2, .left3, .left4, .left5, .left6, .left7, .left8 {
        margin-left: 0;
    }

    .photo-another,.photo-large, .photo-mid, .photo-small {
        width: 100%;
    }

    .works {
        gap: 20rem;
        padding: 20rem;
    }

    .cta-text {
        min-height: 80vh;
        justify-content: center;
        padding-left: 0;
    }

    .left, .right {
        font-size: 8rem;
        gap: 5rem;
        width: 100%;
    }

    .left {
        margin-bottom: 3rem;
    }

    .yourfoot {
        flex-direction: column;
        justify-content: center;
    }

    .min10,.min30,.min60 {
        margin-top: 0;
    }
}