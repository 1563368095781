@keyframes modalAnimation {
    0% {
        transform: scale(0) translateX(-100%) translateY(-100%);
        opacity: 1;
    }

    100% {
        transform: scale(q) translateX(0) translateY(0);
        opacity: 1;
    }
}

@keyframes textAnimation {
    0% {
        opacity: 0;
    }

    20% {
        opacity: .5;
    }

    30% {
        opacity: .8;
    }

    40% {
        opacity: .1;
    }

    50% {
        opacity: .9;
    }

    70% {
        opacity: .3;
    }

    80% {
        opacity: .8;
    }

    90% {
        opacity: .9;
    }

    100% {
        opacity: 1;
    }
}


.elipsa {
    background: conic-gradient(from 90deg at 20% 30%,transparent 0deg,rgb(133 25 25) 86.12deg,transparent 1turn),#c23131;
    border-radius: 50%;
    bottom: 0;
    height: 80vw;
    left: -50rem;
    position: absolute;
    transition: left 2s ease;
    width: 80vw;
    z-index: -1;
    top: 10vh;
    animation: modalAnimation .6s ease-out;
}

.brief {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.brief form {
    font-size: 5rem;
    color: white;
    padding-bottom: 20rem;
    padding-top: 10rem;
    padding-left: calc((100vw)/9);
    font-family: 'Cabin';
    text-transform: lowercase;
}

.brief form div {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 50vw;
    position: relative;
    gap: 5rem;
}

.brief p#main-text-cantata {
    font-size: 10rem;
    gap: 0rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: calc((100vw)/9);
}

p#main-text-cantata .black {
    color: black;
    animation: textAnimation 2s ease-out;
}

form .choice {
    width: 100%;
}

.brief .redirect {
    padding-bottom: 50rem;
}

.choice input[type=radio] {
    height: 25px;
    opacity: 0;
    position: absolute;
    width: 25px;
    vertical-align: top;
    z-index: 10;
    top: 1rem;
}

.choice input[type="radio"] + label {
    padding-left: 8rem;
    height: 25px;
    display: inline-block;
    position: relative;
}

.choice input[type=radio]+label:before {
    background-image: url("https://vivalagrid.com/backend/wp-content/uploads/2023/07/uncheck.svg");
    background-size: 20px;
    content: "";
    display: inline-block;
    height: 20px;
    left: 1.4rem;
    margin-right: 10px;
    position: absolute;
    width: 20px;
    top: 1.3rem;
}

.choice input[type="radio"]:checked + label::before {
    background-image: url("https://vivalagrid.com/backend/wp-content/uploads/2023/07/check-2.svg");
}

.brief #cta-button {
    color: white;
}

.brief textarea {
    width: 100%;
    height: 16rem;
    margin: 0 25rem 0 0;
    padding: 3rem 5rem;
    font-size: 3rem;
    font-family: 'Cabin';
    border-radius: 0;
}

.modal {
    background-color: hsla(0,0%,100%,0);
    left: 0;
    padding: 0;
    position: absolute;
    z-index: 1000;
    bottom: 0;
    max-height: 100vh;
    overflow: hidden;
    width: 100vw;
    top: 0;
    height: 100%;
    backdrop-filter: hue-rotate(25deg) grayscale(1);
    --ep-grid-line-color: #22222212;
    --ep-grid-line-columns: 9;
    --ep-grid-line-width: 1.5px;
    margin: 0;
}

.modal:before {
    background-size: calc(100% + var(--ep-grid-line-width)) 100%;
    bottom: 0;
    content: "";
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-width: var(--ep-grid-line-max-width);
    min-height: 100vh;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
    z-index: var(--ep-grid-line-z-index);
    background-image: repeating-linear-gradient(var(--ep-grid-line-direction),var(--ep-grid-line-column-color),var(--ep-grid-line-column-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns) - var(--ep-grid-line-width)),var(--ep-grid-line-color) calc(100%/var(--ep-grid-line-columns)));
}

#brief .choice {
    width: fit-content;
}

#brief label {
    width: 100%;
    display: flex;
    align-items: center;
}

label.head {
    text-transform: uppercase;
    font-size: 3rem;
    letter-spacing: 4px;
}

button.close {
    background: url(https://vivalagrid.com/backend/wp-content/uploads/2023/06/close.svg) no-repeat;
    background-size: cover;
    height: 30px;
    position: absolute;
    left: calc((100vw)/18);
    top: 15rem;
    width: 30px;
    border: none;
    color: #7fffd400;
}

#brief input[type="text"], #brief input[type="email"] {
    padding: 3rem 5rem;
    font-family: 'Cabin';
    border-radius: 0;
    border: none;
}

.thanks {
    font-size: 10rem;
    gap: 0rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-family: 'Cantata One';
    text-transform: initial;
}

#brief input[type="date"] {
    font-family: 'Cabin';
    padding: 3rem 5rem;
    border-radius: 0;
    color: #12191d;
}

@media only screen and (max-width: 1680px) {
    .brief form {
        padding-bottom: 20rem;
    }

    .elipsa {
        left: -35rem;
    }
}


@media only screen and (max-width: 1550px) {
    
    .brief form {
        padding-bottom: 15rem;
    }
    .elipsa {
        left: -30rem;
        top: 5vh;
    }
}

@media only screen and (max-width: 1025px) {
    .modal {
    --ep-grid-line-columns: 5;
        }
}

@media only screen and (max-width: 600px) {
    .modal {
        --ep-grid-line-columns: 2;
    }

    .elipsa {
        height: calc(100 * var(--vh));
        left: 0;
        top: 0;
        width: 100%;
        border-radius: 0;
    }

    .brief {
        height: calc(100 * var(--vh));
    }

    .brief form div {
        padding: 2rem 0;
        width: 100%;
    }

    .choice input[type=radio]+label {
        height: 13rem;
        padding-left: 18rem;
    }

    .brief p#main-text-cantata {
        font-size: 15rem;
    }

    .choice input[type=radio]+label:before {
        background-size: 25px;
        height: 25px;
        left: 0px;
        top: 0;
        width: 25px;
    }

    .brief form {
        font-size: 8rem;
    }

    label.head {
        font-size: 5rem;
    }

    .brief textarea {
        font-size: 7rem;
        height: 40rem;
        padding: 5rem;
    }

    #brief input[type=email], #brief input[type=text] {
        font-size: 7rem;
        padding: 5rem;
        width: 100%;
    }

    button.close {
        left: 5vw;
    }

    .brief form {
        padding-left: 4vw;
        padding-right: 4vw;
    }

    .brief {
        justify-content: flex-end;
    }

    .brief p#main-text-cantata {
        padding-left: 4vw;
    }


}

@media only screen and (max-width: 360px) {

}
  